import React from "react"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content } from "page_components/config"

import ConfigProvider from "context/config"
import { useProducts } from "context/products"

const ConfigContent = ({ location }) => {
  const { productsEN } = useProducts()

  const formatted_products = productsEN?.filter(item =>
    item?.productCategories?.nodes?.some(item => item?.slug === "tapety")
  )

  return (
    <ConfigProvider location={location} products={formatted_products}>
      <Content />
    </ConfigProvider>
  )
}

const Config = ({ location }) => {
  return (
    <Layout location={location}>
      <Breadcrumbs title="Configurator" />
      <PageHeader title="Customize the project to your needs" />
      <ConfigContent location={location} />
    </Layout>
  )
}

export const Head = () => <Seo title="Configurator" />

export default Config
